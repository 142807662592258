import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout/Layout';

import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';

import LoginContainer from '../components/Container/Login/LoginContainer/LoginContainer';
import {PageTemplateStyled} from '../templates/styles';

class LoginPage extends Component {
	render() {
		const {location} = this.props;
		return null;

		return (
			<>
				<Meta title={appendSeoTitleSuffix('Melde dich bei MS-Life an')} />
				<Layout location={location}>
					<PageTemplateStyled>
						<Section showPadding>
							<LoginContainer />
						</Section>
					</PageTemplateStyled>
				</Layout>
			</>
		);
	}
}

LoginPage.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

export default LoginPage;
